@import 'bootstrap-4-grid/scss/grid.scss';
@import '~styles/var';

@keyframes prev {
  from {
    margin-left: calc(-20% - 10px);
  }
  to {
    margin-left: 0;
  }
}

@keyframes prev-mobile-from-prev {
  from { top: -136px; }
  to { top: 0; }
}

@keyframes prev-mobile-from-next {
  from { top: -68px; }
  to { top: 0; }
}

.PartnerNav {
  position: relative;
  & > .prev, & > .next {
    position: absolute;
    .arrow {
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 13px;
      height: 13px;
      position: absolute;
      transform: translate(-50%, -50%);
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        display: inline-block;
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
      }
    }
  }
  .road {
    overflow: hidden;
    .horse {
      display: inline-block;
    }
  }
  @include media-breakpoint-up(lg) {
    & > .prev, & > .next {
      top: 0;
      width: 30px;
      height: 100%;
      cursor: pointer;
      background-color: rgba(#4c4c4c, .8);
      &:hover {
        background-color: #4c4c4c;
      }
      .arrow {
        &:before {
          transform: rotate(45deg);
        }
      }
    }
    & > .prev {
      left: 0;
      .arrow {
        left: 55%;
        &:before {
          transform: rotate(225deg);
        }
      }
    }
    & > .next {
      right: 0;
    }
    .road {
      white-space: nowrap;
      &.alignNext {
        .horse:first-of-type {
          margin-left: -40px;
        }
      }
      .horse {
        width: 20%;
        &:not(:first-of-type) {
          margin-left: 10px;
        }
      }
      &.nextMoving {
        .horse:first-of-type {
          transition: margin-left .3s;
          margin-left: calc(-20% - 50px);
        }
      }
      &.prevMoving {
        .horse:first-of-type {
          margin-left: calc(-20% - 10px);
          animation-name: prev;
          animation-duration: .3s;
          animation-fill-mode: forwards;
        }        
      }
    }
  }
  @include media-breakpoint-down(md) {
    & > .prev, & > .next {
      width: 100%;
      height: 30px;
      z-index: 2;
      background-color: rgba($nice-blue, .8);
      .arrow {
        &:before {
          transform: rotate(-45deg);
        }
      }
    }
    & > .prev {
      top: 0;
      .arrow:before {
        top: 3px;
      }
    }
    & > .next {
      bottom: 0;
      .arrow:before {
        transform: rotate(-225deg);
      }
    }
    .road {
      height: 330px;
      &.alignNext .horse {
        top: -68px;
      }
      &.nextMoving {
        .horse {
          transition: top .3s;
        }
        &.alignPrev .horse {
          top: -68px;
        }
        &.alignNext .horse {
          top: -204px;
        }
      }
      &.prevMoving {
        .horse {
          animation-duration: .3s;
          animation-fill-mode: forwards;
        }
        // 這邊要分 prev
        &.alignPrev .horse {
          animation-name: prev-mobile-from-prev;
        }
        // 這邊要分 next
        &.alignNext .horse {
          animation-name: prev-mobile-from-next;
        }
      }
      .horse {
        width: calc(50% - 5px);
        margin-bottom: 10px;
        position: relative;
        top: 0;
        &:nth-of-type(odd) {
          margin-right: 10px;
        }
      }
    }
  }
}