$light-blue-grey: #aad8e9;
$black: #1f1f1f;
$white: #f5f5f5;
$brown-grey: #7f7f7f;
$nice-blue: #1b75bb;
$very-light-brown: #cebf91;
$milk-chocolate: #81511c;
$pinkish-orange: #fe6a3f;
$light-tan: #f8dea6;
$rosa: #ff8a98;
$lightblue: #82beec;