@import "~styles/var";
@import 'bootstrap-4-grid/scss/grid.scss';

.Home {
  .block1 .img {
    height: 300px;
    background-color: #eee;
    margin-bottom: 20px;
    &:before {
      content: attr(data-text);
    }
  }
  
  .block {
    padding: 20px;
    background-color: #fff;
    overflow: hidden;
  }

  .rowBlock {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .pt {
    padding-top: 46px;
  }

  .title {
    color: $black;
    font-size: 25px;
    margin-bottom: 20px;
  }

  .df {
    display: flex;
    flex-direction: column;
    & > * {
      flex: 1;
    }
  }

  .youtube {
    width: 420px;
    height: 240px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .list {
    text-align: center;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: left;
      li:not(:last-of-type) {
        margin-bottom: 15px;
      }
      a {
        color: $black;
      }
    }
  }

  .typeNews {
    .news {
      .date {
        font-size: 16px;
        color: $pinkish-orange;
        font-family: Helvetica;
      }
      .type {
        &.activity:after { background-color: $rosa; }
        &.construction:after { background-color: $light-tan; }
        &:after {
          float: right;
          color: $black;
          font-size: 13px;
          padding: 0 6px;
          content: attr(data-type);
          background-color: #d7d7d7;
        }
      }
      .intro {
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .more {
    margin: 30px 0 0;
    font-size: 16px;
    padding: 5px 16px;
    border-radius: 10px;
    display: inline-block;

    color: #fff;
    background-color: $nice-blue;
    border: 1px solid transparent;
    &:hover {
      color: $nice-blue;
      background-color: #fff;
      border: 1px solid $nice-blue;
      &:after {
        border-color: $nice-blue;
      }
    }
    &:after {
      top: -1px;
      width: 10px;
      height: 10px;
      content: '';
      margin-left: 5px;
      position: relative;
      display: inline-block;
      transform: rotate(45deg);
      border-top: 3px solid #fff;
      border-right: 3px solid #fff;
    }
  }

  .externalLinks {
    background-size: 146px 147px;
    background-repeat: no-repeat;
    background-image: url('./images/earth@2x.png');
    background-position: calc(100% + 50px) -43%;
    overflow-y: scroll;
    ul {
      font-size: 14px;
      li:before {
        top: -1px;
        content: '';
        width: 10px;
        height: 10px;
        margin-right: 10px;
        position: relative;
        display: inline-block;
        transform: rotate(45deg);
        border-top: 3px solid $lightblue;
        border-right: 3px solid $lightblue;
        
      }
    }
  }

  .liveEnv {
    ul {
      margin: 0;
      padding: 0 10px;
      list-style: none;
      font-size: 15px;
      li {
        margin-top: 20px;
        div {
          &:first-of-type {
            color: $nice-blue;
          }
          &:last-of-type {
            position: relative;
            &:before {
              content: '';
              display: block;
              background: no-repeat;
              background-size: contain;
              background-position: center;
            }
            &.aq0:before { background-image: url(./images/good.png); }
            &.aq1:before { background-image: url(./images/normal.png); }
            &.aq2:before { background-image: url(./images/bad.png); }
            &.sun:before {
              color: $pinkish-orange;
              content: attr(data-number);
              background-image: url(./images/sun@2x.png);
            }
          }
        }
        a {
          flex: 3 1;
          .youtubeLive {
            height: 25px;            
            background: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url(./images/Youtube-logo-vector-PNG.png); 
          }
        }
      }
    }
  }

  .dotsClass {
    position: absolute;
    right: 10px;
    top: -40px;
  }

  .sister {
    .item {
      border: 1px solid #ccc;
      .img {
        height: 100px;
        background-size: cover;
        @include media-breakpoint-down(md) {
          background-position: -15px;
        }
      }
      .text {
        color: #fff;
        text-align: center;
        background-color: #4c4c4c;
        font-size: 0.8em;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .block1 {
      padding: 50px;
      background-color: #fff;
    }

    .first {
      padding-left: 0;
      padding-right: 0;
    }

    .last {
      padding-left: 0;
      padding-right: 0;
      height: 400px;
    }

    .sister {
      margin: 0 -15px;
    }

    .liveEnv {
      ul {
        li {
          display: flex;
          div {
            flex: 3;
            &:first-of-type {
              flex: 4;
            }
            &:last-of-type {
              position: relative;
              &:before {
                top: 50%;
                left: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
              }
              &.aq0:before,
              &.aq1:before,
              &.aq2:before {
                top: calc(50% - 5px);
              }
              &.sun:before {
                padding-top: 7px;
                text-align: center;
              }
              &.aq0:before,
              &.aq1:before,
              &.aq2:before {
                width: 30px;
                height: 35px;
              }
              &.sun:before {
                width: 38px;
                height: 38px;
              }
            }
          }
        }
      }
    }

    .title {
      padding: 0 10px;
    }

    .df:nth-of-type(1) {
      padding: 0 20px;
    }
  }
  @include media-breakpoint-down(md) {
    .block1 {
      margin-top: 120px;
    }
    .typeNews {
      padding: 5px;
      padding-bottom: 50px;
      background-color: #fff;
    }
    .df {
      margin: 10px 0;
    }
    .externalLinks {
      ul {
        font-size: 1em;
      }
    }

    .liveEnv {
      ul {
        font-size: 25px;
        display: flex;
        li {
          flex: 1;
          div {
            text-align: center;
            // &:nth-of-type(2) {
            //   display: none;
            // }
            &:last-of-type {
              //margin-top: 20px;
              &:before {
                margin: 0 auto;
              }
              &.aq0:before,
              &.aq1:before,
              &.aq2:before,
              &.sun:before {
                margin-top: 30px;
                width: 82px;
                height: 82px;
                font-size: 36px;
                padding-top: 11px;
              }
            }
          }
          a {
            .youtubeLive {
              margin: 30px auto;
              width: 82px;
              height: 82px;
              padding-top: 11px;
            }
          }
        }
      }
    }
  }
}

.swiperBanner1 {
  position: relative;
  background-size: cover;
  background-position: center;

  @include media-breakpoint-up(lg) {
    top: 120px;
    height: calc(100vh - 120px);
  }

  @include media-breakpoint-down(md) {
    top: 55px;
    height: calc(100vh - 55px);
  }
}

.swiperBanner2 {
  position: relative;
  background-size: cover;
  background-position: center;
  // background-image: url('./images/官網首頁照片_2.jpg');

  @include media-breakpoint-up(lg) {
    top: 120px;
    height: calc(100vh - 120px);
  }

  @include media-breakpoint-down(md) {
    top: 55px;
    height: calc(100vh - 55px);
  }
}