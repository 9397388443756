@import 'bootstrap-4-grid/scss/grid.scss';
@import "~styles/var";
.Carousel {
  position: relative;
  text-align: center;
  background-color: #fff;
  .road {
    overflow: hidden;
    white-space: nowrap;
    &.move {
      .horse:first-of-type {
        margin-left: -100%;
        transition: margin-left .3s;
      }
    }
    .horse {
      width: 100%;
      display: inline-block;
    }
  }
  .dotsBlock {
    margin: 0;
    padding: 0;
    display: inline-block;
    li {
      width: 11px;
      height: 11px;
      cursor: pointer;
      border-radius: 100%;
      display: inline-block;
      background-color: #d4d4d4;
      &.active {
        background-color: #82beec;
      }
      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .road {
      margin-bottom: 15px;
      .horse {
        padding: 0 10px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .road {
      margin-bottom: 15px;
      .horse {
        font-size: 32px;
        div {
          white-space: normal;
        }
      }
    }
  }
}